import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import Icons from "../../utils/icons";
import { useAppSelector } from "../../redux/hooks";
import { KRoutes } from "../../routers/router";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { getAdminProfile } from "../../services/admins/admin";

const ScreenDashboard = () => {
  const { profile, isLoading } = useAppSelector((state) => state.admins);

  const navigate = useNavigate();

  useEffect(() => {
    getAdminProfile();
  }, []);

  useEffect(() => {
    if (!profile) {
    } else if (profile.role === "SuperAdmin") {
      navigate(KRoutes.admins);
    } else if (profile.role === "Admin") {
      navigate(KRoutes.vips);
    } else if (profile.role === "VIP") {
      navigate(KRoutes.tickets);
    } else if (profile.role === "BranchManager") {
      navigate(KRoutes.tickets);
    } else if (profile.role === "BranchWorker") {
      navigate(KRoutes.assignedTickets);
    } else {
      window.location.reload();
    }
  }, [profile, navigate]);

  return (
    <Layout title="dashboard" sidebarHide>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide min-h-full min-w-full">
          <div className="flex justify-between">
            <p className="text-3xl font-black dark:text-white ">Dashboard</p>
          </div>
          <div className="mt-36 flex justify-center items-center w-full h-full">
            <div className="flex flex-col items-center justify-center">
              <img src={Icons.noData} height={80} width={80} alt="empty" />
              <h1 className="mt-2 text-base text-primary-color font-medium ">
                Coming Soon
              </h1>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ScreenDashboard;
