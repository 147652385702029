import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVip } from "../types/vip";

interface IVipState {
  vips: IVip[];
  isLoading: boolean;
}

const vipInitState: IVipState = {
  vips: [],
  isLoading: false,
};

const vipSlice = createSlice({
  name: "vips",
  initialState: vipInitState,
  reducers: {
    changeVipLoading: (state: IVipState, action: PayloadAction<boolean>) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setVipsState: (
      state: IVipState,
      action: PayloadAction<{ vips: IVip[]; refresh?: boolean }>
    ) => {
      if (state.vips.length <= 0 || action.payload.refresh) {
        state.vips = action.payload.vips || state.vips;
      }
      state.isLoading = false;
    },
    addVipState: (state: IVipState, action: PayloadAction<IVip>) => {
      state.vips.unshift(action.payload);
      state.isLoading = false;
    },
    editVipState: (state: IVipState, action: PayloadAction<IVip>) => {
      state.vips = state.vips.map((vip) => {
        if (action.payload._id === vip._id) {
          vip = action.payload;
        }
        return vip;
      });
      state.isLoading = false;
    },
    changeVipStatusState: (state: IVipState, action: PayloadAction<IVip>) => {
      state.vips = state.vips.map((vip) => {
        if (
          action.payload._id === vip._id &&
          action.payload.status !== vip.status
        ) {
          vip.status = action.payload.status;
        }
        return vip;
      });
      state.isLoading = false;
    },
    deleteVipState: (state: IVipState, action: PayloadAction<IVip["_id"]>) => {
      state.vips = state.vips.filter((vip) => vip._id !== action.payload);
      state.isLoading = false;
    },
    clearVipState: (state: IVipState) => {
      state.vips = [];
      state.isLoading = false;
    },
  },
});

export const {
  changeVipLoading,
  setVipsState,
  addVipState,
  editVipState,
  changeVipStatusState,
  deleteVipState,
  clearVipState,
} = vipSlice.actions;

export default vipSlice.reducer;
