import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";
import Table from "../../components/Table";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import ticketsTableHeaders from "./ticketTableHeaders";
import { getManagerTickets, getTickets } from "../../services/tickets/ticket";
import { autoRefreshDelay } from "../../core/constants";
import { getBranchList } from "../../services/branches/branch";
import { getWorkers } from "../../services/workers/worker";

const ScreenTickets = () => {
  const { profile } = useAppSelector((state) => state.admins);
  const { tickets, isLoading } = useAppSelector((state) => state.tickets);
  const columns = useMemo(() => ticketsTableHeaders, []);
  const data = useMemo(() => tickets, [tickets]);

  useEffect(() => {
    setTimeout(() => {
      if (profile && profile.role === "BranchManager") {
        getManagerTickets();
        getBranchList();
      } else if (profile && ["SuperAdmin", "Admin", "VIP"].includes(profile.role)) {
        getTickets();
      }
    }, 0);
    getWorkers();
    const refreshInterval = setInterval(
      () =>
        ["SuperAdmin", "Admin", "VIP"].includes(profile?.role ?? "")
          ? getTickets(true)
          : getManagerTickets(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, [profile]);

  return (
    <Layout title="tickets">
      {isLoading && tickets.length <= 0 ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={data}
          tableTitle={"Tickets"}
          buttons={{
            addButton: false,
            exportButton: false,
          }}
        />
      )}
    </Layout>
  );
};

export default ScreenTickets;
