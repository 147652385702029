import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAppSelector } from "../../redux/hooks";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import {
  getAssignedTickets,
  resolveTicketStatus,
} from "../../services/tickets/ticket";
import ImageViewer from "react-simple-image-viewer";
import getCroppedImg from "../../utils/cropImage";
import Cropper, { Area } from "react-easy-crop";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";

import "react-html5-camera-photo/build/css/index.css";

// Import Swiper styles
import "swiper/css";

import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { ITicket } from "../../types/ticket";
import { KRoutes } from "../../routers/router";

import ApiEndpoints from "../../core/apiEndpoints";

const ScreenAssignedTickets = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { assignedTickets } = useAppSelector((state) => state.tickets);

  const [openCamera, setOpenCamera] = useState(false);
  const [isImageViewerOpen, setImageViewerOpen] = useState(false);
  const [viewableImages, setViewableImages] = useState<string[]>([]);
  const [viewedImageIndex, setViewedImageIndex] = useState<number>(0);
  const [isCropperOpen, setCropperOpen] = useState(false);
  const [cropImageIndex, setCropImageIndex] = useState<number>(0);
  const [capturedImages, setCapturedImages] = useState<string[]>([]);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const navigate = useNavigate();
  const params = useParams();

  const [ticket, setTicket] = useState<ITicket | null>(null);

  useEffect(() => {
    getAssignedTickets();
  }, []);

  const setTicketFields = useCallback(
    (id: string) => {
      const ticket = assignedTickets.find((t) => t._id === id);
      if (ticket) {
        if (ticket.status != "ASSIGNED") {
          navigate(-1);
        } else {
          setTicket(ticket);
        }
      }
    },
    [assignedTickets]
  );

  useEffect(() => {
    if (params.tid) {
      setTicketFields(params.tid);
    }
  }, [params.tid, setTicketFields]);

  const handleOpenCamera = () => {
    if (capturedImages.length < 3) {
      setOpenCamera(true);
    } else {
      toast.warn("Only three image allowed");
    }
  };

  const dataURItoBlob = (dataURI: string): Blob => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const handleCrop = async (status: "crop" | "close") => {
    if (croppedAreaPixels && status === "crop") {
      const croppedImage = await getCroppedImg(
        capturedImages[cropImageIndex],
        croppedAreaPixels,
        0,
        { horizontal: false, vertical: false } // Flip (if any)
      );
      if (croppedImage) {
        let updatedCapturedImages = [...capturedImages];
        updatedCapturedImages[cropImageIndex] = croppedImage;
        setCapturedImages(updatedCapturedImages);
      }
    }
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCropperOpen(false);
  };

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    setLoading(true);
    if (!ticket || !capturedImages || capturedImages.length < 0) {
      setLoading(false);
      toast.warn("Provide images");
      return;
    }
    const ticketForm = new FormData();

    ticketForm.append("status", "RESOLVED");
    capturedImages.map((image) => {
      ticketForm.append("images", dataURItoBlob(image));
    });
    resolveTicketStatus(ticket!._id, ticketForm)
      .then((resp) => {
        if (resp) {
          navigate(KRoutes.assignedTickets);
        }
      })
      .catch((error: any) => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  return (
    <Layout title="assigned-tickets" sidebarHide>
      {isLoading && !ticket ? (
        <Loader />
      ) : openCamera && ticket ? (
        <div>
          <Camera
            onTakePhoto={(dataURL: string) => {
              console.log(dataURL);
              setCapturedImages((prevImages) => [...prevImages, dataURL]);
              setOpenCamera(false);
            }}
            imageType={IMAGE_TYPES.JPG}
            // idealResolution={{ width: 640, height: 480 }}
            // imageCompression={0.97}
            // isMaxResolution={true}
            // isImageMirror={false}
            // isSilentMode={false}
            // isDisplayStartCameraError={true}
            // isFullscreen={true}
            // sizeFactor={1}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            onCameraError={(error) => {
              console.log("ERROR", error);
            }}
          />
        </div>
      ) : ticket ? (
        <div>
          {isImageViewerOpen && viewableImages.length > 0 && (
            <ImageViewer
              src={viewableImages}
              currentIndex={viewedImageIndex}
              onClose={() => setImageViewerOpen(false)}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            />
          )}
          {isCropperOpen && capturedImages.length > 0 && (
            <>
              <div className="w-full bg-gray-800 md:h-400">
                <Cropper
                  image={capturedImages[cropImageIndex]}
                  aspect={4 / 3}
                  crop={crop}
                  zoom={zoom}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={(croppedArea, croppedAreaPixels) =>
                    setCroppedAreaPixels(croppedAreaPixels)
                  }
                />
                <div
                  className="flex items-center justify-center gap-x-6 h-0"
                  style={{
                    margin: "auto",
                    position: "absolute",
                    top: "580px",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 11,
                  }}
                >
                  <button
                    type="button"
                    className="rounded-md bg-danger-color px-3 py-2 text-sm font-semibold text-light-color shadow-sm hover:bg-light-danger-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-light-color"
                    onClick={() => handleCrop("close")}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => handleCrop("crop")}
                    className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-light-color shadow-sm hover:bg-light-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-light-color"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="relative w-full h-200 bg-gray-800 md:h-400"></div>
            </>
          )}

          <div className="space-y-12 pt-28 pb-10 md:py-36 px-6 md:px-[12rem] lg:px-[20rem] xl:px-[30rem] 2xl:px-[44rem]">
            <div className="border-b border-gray-900/10 pb-6">
              <div className="">
                <h2 className="text-xl md:text-4xl uppercase font-black my-4 text-center">
                  {(ticket as ITicket).code}
                </h2>
                <p className="text-sm text-justify">${ticket.note}</p>
              </div>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full">
                  <label
                    htmlFor="photo"
                    className="block text-sm font-medium leading-6 text-prmary-color"
                  >
                    Query Images
                  </label>
                  <div className="mt-2 flex items-center gap-x-3">
                    {ticket.images &&
                      ticket.images.map((ticketImage, index) => (
                        <li
                          key={index}
                          className={`relative mr-4 list-none ${
                            isCropperOpen && "hidden"
                          }`}
                        >
                          <div className="h-23 w-23 text-gray-300 relative rounded-full">
                            <img
                              src={
                                ApiEndpoints.baseImageUrl + "/" + ticketImage
                              }
                              alt={`Captured Image ${index + 1}`}
                              onClick={() => {
                                setImageViewerOpen(true);
                                setViewableImages(
                                  ticket.images.map(
                                    (image) =>
                                      ApiEndpoints.baseImageUrl + "/" + image
                                  )
                                );
                                setViewedImageIndex(index);
                              }}
                            />
                          </div>
                        </li>
                      ))}
                  </div>
                </div>
              </div>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full">
                  <label
                    htmlFor="photo"
                    className="block text-sm font-medium leading-6 text-prmary-color"
                  >
                    Resolve Images
                  </label>
                  <div className="mt-2 flex items-center gap-x-3">
                    {capturedImages &&
                      capturedImages.map((capturedImage, index) => (
                        <li
                          key={index}
                          className={`relative mr-4 list-none ${
                            isCropperOpen && "hidden"
                          }`}
                        >
                          <div className="h-23 w-23 text-gray-300 relative rounded-full">
                            <img
                              src={capturedImage}
                              alt={`Captured Image ${index + 1}`}
                              onClick={() => {
                                setImageViewerOpen(true);
                                setViewableImages(capturedImages);
                                setViewedImageIndex(index);
                              }}
                            />
                            <button
                              className="absolute top-0 right-8 p-1 cursor-pointer text-white bg-blue-500 rounded-full"
                              onClick={() => {
                                setCropperOpen(true);
                                setCropImageIndex(index);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15 4s1.5-2 2.5-2 2 .5 2 2-1.5 2-1.5 2M17 6L5 18"
                                />
                              </svg>
                            </button>
                            <button
                              className="absolute top-0 right-0 p-1 cursor-pointer text-white bg-red-500 rounded-full"
                              onClick={() =>
                                setCapturedImages(
                                  capturedImages.filter((_, i) => i !== index)
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        </li>
                      ))}
                    {capturedImages.length === 0 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-26 w-26 text-gray-300"
                        onClick={handleOpenCamera}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                        />
                      </svg>
                    )}
                    {capturedImages.length <= 1 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-26 w-26 text-gray-300"
                        onClick={handleOpenCamera}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                        />
                      </svg>
                    )}
                    {capturedImages.length <= 2 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-26 w-26 text-gray-300"
                        onClick={handleOpenCamera}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                        />
                      </svg>
                    )}
                  </div>
                  <div className="flex justify-center items-center">
                    <button
                      type="button"
                      onClick={handleOpenCamera}
                      className="rounded-md bg-white px-2.5 py-1.5 mt-6 text-sm font-semibold text-prmary-color shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Open Camera
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-x-6 h-0">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-prmary-color"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default ScreenAssignedTickets;
