import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeAdminLoading,
  setAdminsState,
  addAdminState,
  changeAdminStatusState,
  deleteAdminState,
  editAdminState,
  setAdminDetails,
  setAdminLoggedIn,
  adminLogoutState,
} from "../../redux/admin";

/**
 * To get a  admins from server
 */
export const getAdmins = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.admin);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAdminsState({ admins: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To admin from server
 */
export const getAdminProfile = async () => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.adminProfile);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAdminDetails(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To login a admin from server api
 */
export const login = async (data: {
  phone?: string;
  email?: string;
  password: string;
  captchaResponse: string;
}) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.login, data);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setAdminLoggedIn({
        token: resp.data.results.token,
        role: resp.data.results.role,
      })
    );
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To add a new  admin
 */
export const addAdmin = async (adminData: any) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.admin, adminData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addAdminState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To add a new  admin
 */
export const editAdmin = async (id: string, adminData: any) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.admin + "/" + id,
      adminData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editAdminState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To update a user firebase token
 */
export const updateFirebaseToken = async (
  token: string,
  type: "primary" | "secondary"
) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.adminUpdateFToken, {
      ftoken: {
        primary: type === "primary" ? token : null,
        secondary: type === "secondary" ? token : null,
      },
    });

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To change the status
 */
export const changeAdminStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.changeAdminStatus + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeAdminStatusState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To delete the admin
 */
export const deleteAdmin = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.admin + "/" + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteAdminState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};

/**
 * To delete the admin permanently
 */
export const logoutAdmin = async () => {
  try {
    // Set Loading
    dispatch(changeAdminLoading(true));

    // API Fetching
    await httpClient().patch(ApiEndpoints.logout);

    // Update to Store
    dispatch(adminLogoutState());
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeAdminLoading(false));
  }
};
