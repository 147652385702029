import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  GridView,
  SelectInput,
} from "../../components/TextFieldItems";
import Layout from "../../components/Layout";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KRoutes } from "../../routers/router";
import Loader from "../../components/Loader";
import {
  addBranch,
  editBranch,
  getBranches,
} from "../../services/branches/branch";
import { IBranch } from "../../types/branch";
import { IAdmin } from "../../types/admin";
import { getManagers } from "../../services/managers/manager";

const ScreenAddBranch = () => {
  const { branches, isLoading } = useAppSelector((state) => state.branches);
  const { managers } = useAppSelector((state) => state.managers);

  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [manager, setManager] = useState("");
  const [visibility, setVisibility] = useState("");
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const params = useParams();

  const setEditFields = useCallback(
    (id: string) => {
      const branch = branches.find((b) => b._id === id);

      if (branch) {
        setName(branch.name);
        setLocation(branch.name);
        setStatus(branch.status);
        setVisibility(branch.visibility);

        getManagers(true);

        if (branch.manager) {
          setManager(branch.manager._id);
        }
      }
    },
    [branches]
  );

  useEffect(() => {
    if (params.bid) {
      getManagers(true);
      getBranches(true);
      setEditFields(params.bid);
    }
  }, [params.bid]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!name || !location || !manager) {
      toast("Fill the branch name, location and manager");
      return;
    } else {
      const branchData = {
        name: name,
        location: location,
        manager: manager,
        status: status,
        visibility: visibility,
      };
      const success = params.bid
        ? await editBranch(params.bid, branchData)
        : await addBranch(branchData);
      if (success) {
        getManagers(true);
        navigate(KRoutes.branches);
      }
    }
  };
  return (
    <Layout title="branches">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-bidden scrollbar-bide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
              {params.bid ? "Edit" : "Add"} Branch
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-bidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <TextField
                  label="Name"
                  id="name"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="name"
                  value={name}
                  required={true}
                  placeholder="Branch Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  label="Location"
                  id="location"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="location"
                  value={location}
                  required={true}
                  placeholder="Branch Location"
                  onChange={(e) => setLocation(e.target.value)}
                />
              </>
            </GridView>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <SelectInput
                  data={managers
                    .filter(
                      ({ branch }) =>
                        !branch ||
                        params.bid === (branch as IBranch)._id.toString()
                    )
                    .map(({ _id, name }) => {
                      return {
                        id: _id,
                        title: name,
                      };
                    })}
                  title="Manager"
                  value={manager}
                  onChange={(e) => setManager(e.target.value)}
                />
                {/* <SelectInput
                  data={[
                    {
                      id: "Active",
                      title: "Active",
                    },
                    {
                      id: "Inactive",
                      title: "Inactive",
                    },
                  ]}
                  title="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                />
                <SelectInput
                  data={[
                    {
                      id: "Show",
                      title: "Show",
                    },
                    {
                      id: "Hide",
                      title: "Hide",
                    },
                  ]}
                  title="Visibility"
                  value={visibility}
                  onChange={(e) => setVisibility(e.target.value)}
                /> */}
              </>
            </GridView>

            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="submit"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddBranch;
