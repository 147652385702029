import { Column } from "react-table";
import {
  TicketButtonsPill,
  DateFromatPill,
  LocationPill,
  ResizeTextPill,
  SwipperImageCell,
  TicketStatusPill,
  TextPill,
} from "../../components/TableCells";
import {
  assignTicket,
  changeTicketBranch,
  deleteTicket,
  rejectTicketStatus,
} from "../../services/tickets/ticket";
import { ITicket } from "../../types/ticket";
import { IUser } from "../../types/user";
import ApiEndpoints from "../../core/apiEndpoints";
import store from "../../redux/store";
import { IWorker } from "../../types/worker";
import { IBranch } from "../../types/branch";

const ticketsTableHeaders: Column<ITicket>[] = [
  {
    id: "code",
    Header: "Code",
    accessor: "code",
  },
  {
    id: "image",
    Header: "Image",
    accessor: "images",
    Cell: ({ value }) =>
      SwipperImageCell(
        value.map((image) => ApiEndpoints.baseImageUrl + "/" + image)
      ),
  },
  {
    id: "note",
    Header: "Note",
    accessor: "note",
    Cell: ResizeTextPill,
  },
  {
    id: "branch",
    Header: "Branch",
    accessor: (b) => {
      return b.branch.name;
    },
  },
  {
    id: "location",
    Header: "Location",
    accessor: (b) => {
      return (
        "https://www.google.com/maps?q=" +
        b.geoLocation.latitude +
        "," +
        b.geoLocation.longitude
      );
    },
    Cell: LocationPill,
  },
  {
    id: "duplicate",
    Header: "Duplicate",
    accessor: (b) => {
      return b.duplicateTickets.length;
    },
  },
  {
    id: "createdAt",
    Header: "Raised At",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    id: "createdBy",
    Header: "Raised By",
    accessor: (t) =>
      t.raisedType === "USER" ? (t.raisedBy as IUser).name : "Guest",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: TicketStatusPill,
  },
  {
    id: "assigned",
    Header: "Assigned",
    accessor: (b) => {
      return b.assignedTo ? (b.assignedTo as IWorker).name : "Not Assigned";
    },
  },
  {
    id: "reason",
    Header: "Reason",
    accessor: (b) => {
      return b.rejectedReason ?? "Not Rejected";
    },
  },
  {
    id: "resp-image",
    Header: "Resolved Images",
    accessor: "resolvedImages",
    Cell: ({ value }) =>
      value.length > 0
        ? SwipperImageCell(
            value.map((image) => ApiEndpoints.baseImageUrl + "/" + image)
          )
        : TextPill({ value: "No Images" }),
  },
  {
    Header: "Action",
    accessor: (t) => {
      return {
        _id: t._id,
        status: t.status,
        branch: t.branch,
      };
    },
    Cell: ({
      value,
    }: {
      value: {
        _id: ITicket["_id"];
        status: ITicket["status"];
        branch: ITicket["branch"];
      };
    }) => {
      return TicketButtonsPill({
        currentTicket: value._id.toString(),
        currentBranch: !value.branch
          ? ""
          : typeof value.branch === "string"
          ? value.branch
          : (value.branch as IBranch)._id.toString(),
        assignChangeButton:
          value.status === "PENDING" &&
          ["BranchManager"].includes(
            store.getState().admins.profile?.role || ""
          )
            ? true
            : false,
        branchChangeButton:
          ["PENDING", "ASSIGNED"].includes(value.status) &&
          ["BranchManager"].includes(
            store.getState().admins.profile?.role || ""
          )
            ? true
            : false,
        rejectButton:
          ["PENDING", "ASSIGNED"].includes(value.status) &&
          ["BranchManager"].includes(
            store.getState().admins.profile?.role || ""
          )
            ? true
            : false,
        deleteButton: ["SuperAdmin", "Developer"].includes(
          store.getState().admins.profile?.role || ""
        )
          ? true
          : false,
        onClickAssign: (id: string) => assignTicket(value._id, id),
        onClickBranchChange: (id: string) => changeTicketBranch(value._id, id),
        onClickDelete: () => deleteTicket(value._id),
        onClickReject: (reason: string) =>
          rejectTicketStatus(value._id, reason),
      });
    },
  },
];

export default ticketsTableHeaders;
