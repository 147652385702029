import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../types/user";

interface IUserState {
  users: IUser[];
  isLoading: boolean;
}

const userInitState: IUserState = {
  users: [],
  isLoading: false,
};

const userSlice = createSlice({
  name: "users",
  initialState: userInitState,
  reducers: {
    changeUserLoading: (state: IUserState, action: PayloadAction<boolean>) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setUsersState: (
      state: IUserState,
      action: PayloadAction<{ users: IUser[]; refresh?: boolean }>
    ) => {
      if (state.users.length <= 0 || action.payload.refresh) {
        state.users = action.payload.users || state.users;
      }
      state.isLoading = false;
    },
    addUserState: (state: IUserState, action: PayloadAction<IUser>) => {
      state.users.unshift(action.payload);
      state.isLoading = false;
    },
    editUserState: (state: IUserState, action: PayloadAction<IUser>) => {
      state.users = state.users.map((user) => {
        if (action.payload._id === user._id) {
          user = action.payload;
        }
        return user;
      });
      state.isLoading = false;
    },
    changeUserStatusState: (
      state: IUserState,
      action: PayloadAction<IUser>
    ) => {
      state.users = state.users.map((user) => {
        if (
          action.payload._id === user._id &&
          action.payload.status !== user.status
        ) {
          user.status = action.payload.status;
        }
        return user;
      });
      state.isLoading = false;
    },
    deleteUserState: (
      state: IUserState,
      action: PayloadAction<IUser["_id"]>
    ) => {
      state.users = state.users.filter((user) => user._id !== action.payload);
      state.isLoading = false;
    },
    clearUserState: (state: IUserState) => {
      state.users = [];
      state.isLoading = false;
    },
  },
});

export const {
  changeUserLoading,
  setUsersState,
  addUserState,
  editUserState,
  changeUserStatusState,
  deleteUserState,
  clearUserState,
} = userSlice.actions;

export default userSlice.reducer;
