import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  GridView,
  SelectInput,
} from "../../components/TextFieldItems";
import Layout from "../../components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { KRoutes } from "../../routers/router";
import { addAdmin, editAdmin } from "../../services/admins/admin";
import { useAppSelector } from "../../redux/hooks";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const ScreenAddAdmin = () => {
  const { admins, isLoading } = useAppSelector((state) => state.admins);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("Active");

  const navigate = useNavigate();
  const params = useParams();

  const setEditFields = useCallback(
    (id: string) => {
      const admin = admins.find((b) => b._id === id);
      if (admin) {
        setName(admin.name);
        setEmail(admin.email);
        setPhone(admin.phone);
        setStatus(admin.status);
      }
    },
    [admins]
  );

  useEffect(() => {
    if (params.aid) {
      setEditFields(params.aid);
    }
  }, [params.aid]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!name || !email || !phone) {
      toast("Fill all fields");
      return;
    } else {
      const adminData = {
        name: name,
        email: email,
        phone: phone,
        password: password,
        status: status,
      };
      const success = params.aid
        ? await editAdmin(params.aid, adminData)
        : await addAdmin(adminData);
      if (success) {
        navigate(KRoutes.admins);
      }
    }
  };
  return (
    <Layout title="admins">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide">
          <div className="flex justify-between">
            <p className="lg:text-3xl text-2xl font-black dark:text-white ">
              {params.aid ? "Edit" : "Add"} Admin
            </p>
            <div>
              <button
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800`"
                onClick={() => navigate(-1)}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Back
                </span>
              </button>
            </div>
          </div>
          <form className="mt-6 " onSubmit={handleSubmit}>
            <GridView className="gap-6 mb-6 lg:grid-cols-2">
              <>
                <TextField
                  label="Name"
                  id="name"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="name"
                  value={name}
                  required={true}
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  label="Email"
                  id="email"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="email"
                  value={email}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  title="Provide valid email id"
                  required={true}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  label="Phone"
                  id="phone"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="phone"
                  value={phone}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  title="Provide valid phone id"
                  required={true}
                  placeholder="Phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
                <TextField
                  label="Password (Optional)"
                  id="password"
                  labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  type="text"
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="password"
                  value={password}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,24}$"
                  title="
                  At least one lower character, 
                  At least one upper character, 
                  At least one numeric value,
                  At least one special symbol, 
                  Password length should be in between 8 to 24"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* <SelectInput
                  data={[
                    {
                      id: "Active",
                      title: "Active",
                    },
                    {
                      id: "Inactive",
                      title: "Inactive",
                    },
                  ]}
                  title="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                /> */}
              </>
            </GridView>
            <div className="flex items-center justify-center -mx-3 my-6">
              <div className="w-full lg:w-1/2 px-3">
                <button
                  type="submit"
                  className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default ScreenAddAdmin;
