import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBranch } from "../types/branch";

interface IBranchState {
  branches: IBranch[];
  isLoading: boolean;
}

const branchInitState: IBranchState = {
  branches: [],
  isLoading: false,
};

const branchSlice = createSlice({
  name: "branch",
  initialState: branchInitState,
  reducers: {
    changeBranchLoading: (
      state: IBranchState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setBranchesState: (
      state: IBranchState,
      action: PayloadAction<{ branches: IBranch[]; refresh?: boolean }>
    ) => {
      if (state.branches.length <= 0 || action.payload.refresh) {
        state.branches = action.payload.branches || state.branches;
      }
      state.isLoading = false;
    },
    addBranchState: (state: IBranchState, action: PayloadAction<IBranch>) => {
      state.branches.unshift(action.payload);
      state.isLoading = false;
    },
    editBranchState: (state: IBranchState, action: PayloadAction<IBranch>) => {
      state.branches = state.branches.map((branch) => {
        if (action.payload._id === branch._id) {
          branch = action.payload;
        }
        return branch;
      });
      state.isLoading = false;
    },
    changeBranchStatusState: (
      state: IBranchState,
      action: PayloadAction<IBranch>
    ) => {
      state.branches = state.branches.map((branch) => {
        if (
          action.payload._id === branch._id &&
          action.payload.status !== branch.status
        ) {
          branch.status = action.payload.status;
        }
        return branch;
      });
      state.isLoading = false;
    },
    changeBranchVisibilityState: (
      state: IBranchState,
      action: PayloadAction<IBranch>
    ) => {
      state.branches = state.branches.map((branch) => {
        if (
          action.payload._id === branch._id &&
          action.payload.visibility !== branch.visibility
        ) {
          branch.visibility = action.payload.visibility;
        }
        return branch;
      });
      state.isLoading = false;
    },
    deleteBranchState: (
      state: IBranchState,
      action: PayloadAction<IBranch["_id"]>
    ) => {
      state.branches = state.branches.filter(
        (branch) => branch._id !== action.payload
      );
      state.isLoading = false;
    },
    clearBranchState: (state: IBranchState) => {
      state.branches = [];
      state.isLoading = false;
    },
  },
});

export const {
  changeBranchLoading,
  setBranchesState,
  addBranchState,
  editBranchState,
  changeBranchStatusState,
  changeBranchVisibilityState,
  deleteBranchState,
  clearBranchState,
} = branchSlice.actions;

export default branchSlice.reducer;
