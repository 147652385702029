import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScreenDashboard from "../screens/dashboard/ScreenDashboard";
import ScreenAddAdmin from "../screens/admins/addAdmin";
import ScreenAdmins from "../screens/admins/ScreenAdmins";
import Screenlogin from "../screens/login/ScreenLogin";
import ScreenProfile from "../screens/admins/ScreenProfile";
import ScreenVips from "../screens/vip/ScreenVip";
import ScreenAddVip from "../screens/vip/ScreenAddVip";
import ScreenManagers from "../screens/manager/ScreenManager";
import ScreenAddManager from "../screens/manager/ScreenAddManager";
import ScreenWorkers from "../screens/worker/ScreenWorker";
import ScreenAddWorker from "../screens/worker/ScreenAddWorker";
import ScreenAddBranch from "../screens/branch/ScreenAddBranch";
import ScreenBranches from "../screens/branch/ScreenBranches";
import ScreenUsers from "../screens/user/ScreenUsers";
import ScreenAddUser from "../screens/user/ScreenAddUser";
import ScreenTickets from "../screens/ticket/ScreenTickets";
import ScreenAssignedTickets from "../screens/assignedTickets/ScreenAssignedTickets";
import ScreenSettings from "../screens/settings/ScreenSettings";
import Screen404 from "../screens/error/Screen404";

import PrivateRoute from "./PrivateRoute";
import ScreenResolveTicket from "../screens/assignedTickets/ScreenResolveTicket";

export const enum KRoutes {
  "dashboard" = "/dashboard",
  "login" = "/login",
  "profile" = "/profile",
  "admins" = "/admins",
  "addAdmin" = "/add-admin",
  "editAdmin" = "/edit-admin",
  "vips" = "/vips",
  "addVip" = "/add-vip",
  "editVip" = "/edit-vip",
  "managers" = "/managers",
  "addManager" = "/add-manager",
  "editManager" = "/edit-manager",
  "workers" = "/workers",
  "addWorker" = "/add-worker",
  "editWorker" = "/edit-worker",
  "users" = "/users",
  "addUser" = "/add-user",
  "editUser" = "/edit-user",
  "branches" = "/branches",
  "addBranch" = "/add-branch",
  "editBranch" = "/edit-branch",
  "tickets" = "/tickets",
  "assignedTickets" = "/assigned-tickets",
  "resolveTicket" = "/resolve-ticket",
  "settings" = "/settings",
}

const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={KRoutes.dashboard} />} />
          <Route path={KRoutes.login} element={<Screenlogin />} />
          <Route
            path={KRoutes.dashboard}
            element={<PrivateRoute component={<ScreenDashboard />} />}
          />
          <Route
            path={KRoutes.admins}
            element={<PrivateRoute component={<ScreenAdmins />} />}
          />
          <Route
            path={KRoutes.profile}
            element={<PrivateRoute component={<ScreenProfile />} />}
          />
          <Route
            path={KRoutes.addAdmin}
            element={<PrivateRoute component={<ScreenAddAdmin />} />}
          />
          <Route
            path={KRoutes.editAdmin + "/:aid"}
            element={<PrivateRoute component={<ScreenAddAdmin />} />}
          />

          <Route
            path={KRoutes.managers}
            element={<PrivateRoute component={<ScreenManagers />} />}
          />
          <Route
            path={KRoutes.addManager}
            element={<PrivateRoute component={<ScreenAddManager />} />}
          />
          <Route
            path={KRoutes.editManager + "/:mid"}
            element={<PrivateRoute component={<ScreenAddManager />} />}
          />

          <Route
            path={KRoutes.vips}
            element={<PrivateRoute component={<ScreenVips />} />}
          />
          <Route
            path={KRoutes.addVip}
            element={<PrivateRoute component={<ScreenAddVip />} />}
          />
          <Route
            path={KRoutes.editVip + "/:vid"}
            element={<PrivateRoute component={<ScreenAddVip />} />}
          />

          <Route
            path={KRoutes.workers}
            element={<PrivateRoute component={<ScreenWorkers />} />}
          />
          <Route
            path={KRoutes.addWorker}
            element={<PrivateRoute component={<ScreenAddWorker />} />}
          />
          <Route
            path={KRoutes.editWorker + "/:wid"}
            element={<PrivateRoute component={<ScreenAddWorker />} />}
          />
          <Route
            path={KRoutes.users}
            element={<PrivateRoute component={<ScreenUsers />} />}
          />
          <Route
            path={KRoutes.addUser}
            element={<PrivateRoute component={<ScreenAddUser />} />}
          />
          <Route
            path={KRoutes.editUser + "/:uid"}
            element={<PrivateRoute component={<ScreenAddUser />} />}
          />
          <Route
            path={KRoutes.branches}
            element={<PrivateRoute component={<ScreenBranches />} />}
          />
          <Route
            path={KRoutes.addBranch}
            element={<PrivateRoute component={<ScreenAddBranch />} />}
          />
          <Route
            path={KRoutes.editBranch + "/:bid"}
            element={<PrivateRoute component={<ScreenAddBranch />} />}
          />
          <Route
            path={KRoutes.tickets}
            element={<PrivateRoute component={<ScreenTickets />} />}
          />
          <Route
            path={KRoutes.assignedTickets}
            element={<PrivateRoute component={<ScreenAssignedTickets />} />}
          />
          <Route
            path={KRoutes.resolveTicket + "/:tid"}
            element={<PrivateRoute component={<ScreenResolveTicket />} />}
          />
          <Route
            path={KRoutes.settings}
            element={<PrivateRoute component={<ScreenSettings />} />}
          />
          <Route path="*" element={<Screen404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
