import React, { useCallback, useEffect, useState } from "react";
import Router from "./routers/router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import {
  resetAllMessage,
  setErrorMessage,
  setWarningMessage,
} from "./redux/notification";
import {
  getAdminProfile,
  getAdmins,
  updateFirebaseToken,
} from "./services/admins/admin";
import { getUsers } from "./services/users/user";
import { getManagers } from "./services/managers/manager";
import { getBranches } from "./services/branches/branch";
import {
  getAssignedTickets,
  getManagerTickets,
  getTickets,
} from "./services/tickets/ticket";
import { onMessageListener, requestForToken } from "./firebase";
import { getWorkers } from "./services/workers/worker";
import { getVips } from "./services/vips/vip";

const App = () => {
  const { hasError, hasNotification, hasSuccess, hasWarning, message } =
    useAppSelector((state) => state.notification);
  const { profile, isLoggedIn } = useAppSelector((state) => state.admins);

  const dispatch = useAppDispatch();
  const [isFetched, setFetched] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  onMessageListener();

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // You can adjust the breakpoint based on your needs
      const isMobileDevice = screenWidth <= 768;

      setIsMobile(isMobileDevice);
    };

    // Initial check on mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      (hasError || hasNotification || hasSuccess || hasWarning) &&
      message.length > 0
    ) {
      toast(message, {
        className: "m-2",
      });
      dispatch(resetAllMessage());
    }
  }, [hasError, hasNotification, hasSuccess, hasWarning, message, dispatch]);

  // Fetch all data from backend on startup if login
  const fetchAllDatas = useCallback(() => {
    getAdminProfile();
    if (profile && profile.role === "BranchManager") {
      console.log("Fetching all");
      getManagerTickets();
      getWorkers();
    } else if (profile && profile.role === "BranchWorker") {
      getAssignedTickets();
    } else if (profile && ["SuperAdmin", "Admin"].includes(profile.role)) {
      getAdmins();
      getVips();
      getManagers();
      getWorkers();
      getUsers();
      getBranches();
      getTickets();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (!isFetched) {
        requestForToken()
          .then((token) => {
            if (token) {
              updateFirebaseToken(token, isMobile ? "secondary" : "primary");
            } else {
              dispatch(
                setWarningMessage(
                  "No registration token available. Request permission to generate one."
                )
              );
            }
          })
          .catch((error: any) => dispatch(setErrorMessage(error.message)));
        fetchAllDatas();
        setFetched(true);
      }
    }
  }, [isLoggedIn, fetchAllDatas, isFetched]);

  return (
    <div>
      <ToastContainer />
      <Router />
    </div>
  );
};

export default App;
