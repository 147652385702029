import { Navigate } from "react-router-dom";
import { LocalStorageKeys } from "../core/localStorageKeys";
import { KRoutes } from "./router";

const PrivateRoute = ({ component, ...rest }: any) => {
  const adminToken = localStorage.getItem(LocalStorageKeys.adminToken);

  return !adminToken ? <Navigate to={KRoutes.login} /> : component;
};

export default PrivateRoute;
