import { Column } from "react-table";
import { SelectColumnFilter } from "../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  StatusPill,
} from "../../components/TableCells";
import store from "../../redux/store";
import { KRoutes } from "../../routers/router";
import { changeAdminStatus, deleteAdmin } from "../../services/admins/admin";
import { IAdmin } from "../../types/admin";

const adminTableHeaders: Column<IAdmin>[] = [
  {
    id: "code",
    Header: "Code",
    accessor: "code",
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "email",
    Header: "Email",
    accessor: "email",
  },
  {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
  },
  {
    id: "role",
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },
  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: false,
        editButton: true,
        deleteButton: ["SuperAdmin", "Developer"].includes(
          store.getState().admins.profile?.role || ""
        )
          ? true
          : false,
        editPath: KRoutes.editAdmin + "/" + value,
        onClickStatusChange: () => changeAdminStatus(value),
        onClickDelete: () => deleteAdmin(value),
      });
    },
  },
];

export { adminTableHeaders };
