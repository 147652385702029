import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { adminLogoutState } from "../redux/admin";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setNavbarActions, setOpenSidebar } from "../redux/settings";
import { KRoutes } from "../routers/router";
import Icons from "../utils/icons";
import { APP_NAME } from "../core/constants";
import { logoutAdmin } from "../services/admins/admin";

const Navbar = ({ sidebarHide = false }: { sidebarHide?: boolean }) => {
  const { openSidebar, navbarActions } = useAppSelector(
    (state) => state.settings
  );
  const { isLoggedIn, profile } = useAppSelector((state) => state.admins);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(KRoutes.login);
    }
  }, [isLoggedIn, navigate]);

  return (
    <nav
      className={`h-20 px-3 ${
        !sidebarHide
          ? openSidebar
            ? "w-[calc(100%_-_18rem)]"
            : "lg:w-[calc(100%_-_5rem)] w-full"
          : "w-full"
      } bg-primary-color flex items-center justify-between fixed z-50`}
    >
      <div className="flex">
        <div>
          {sidebarHide ? (
            <div
              className={`flex items-center justify-start h-20 fixed top-0 left-0 bg-primary-color`}
            >
              <img
                className={`cursor-pointer ml-5`}
                src={Icons.logo}
                alt="Logo"
                width={openSidebar ? 50 : 40}
              />
              <h1 className={`text-white origin-left font-medium text-xl ml-2`}>
                {APP_NAME.toUpperCase()}
              </h1>
            </div>
          ) : (
            <div>
              <button onClick={() => dispatch(setOpenSidebar(!openSidebar))}>
                <img
                  src={!openSidebar ? Icons.menu : Icons.close}
                  alt="Menu"
                  width={28}
                />{" "}
              </button>
            </div>
          )}
        </div>
      </div>

      <div>
        <button
          type="button"
          className="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
          id="user-menu-button"
          aria-expanded="false"
          data-dropdown-toggle="dropdown"
          onClick={() => dispatch(setNavbarActions("userProfile"))}
        >
          <span className="sr-only">Open user menu</span>
          <img
            className="w-8 h-8 rounded-full"
            src={Icons.profile}
            alt="User Profile"
          />
        </button>
        <div
          className={`${
            !navbarActions.userProfile && "hidden"
          } z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 absolute top-12 right-0.5`}
          id="dropdown"
        >
          <div className="py-3 px-4">
            <span className="block text-sm text-gray-900 dark:text-white">
              {profile?.name ?? "Admin"}
            </span>
            <span className="block text-sm font-medium text-gray-500 truncate dark:text-gray-400">
              {profile?.email ?? "No Mail"}
            </span>
          </div>
          <ul className="py-1" aria-labelledby="dropdown">
            <li className="w-full">
              <button
                onClick={() => {
                  navigate("/profile");
                  dispatch(setNavbarActions("userProfile"));
                }}
                className="block w-full text-start py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"
              >
                Profile
              </button>
            </li>

            {/* <li className="w-full">
              <button
                onClick={() => {
                  navigate("/settings");
                  dispatch(setNavbarActions("userProfile"));
                }}
                className="block w-full text-start py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"
              >
                Settings
              </button>
            </li> */}

            <li className="w-full">
              <button
                onClick={() => {
                  logoutAdmin();
                  dispatch(setNavbarActions("userProfile"));
                }}
                className="block w-full text-start py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"
              >
                Sign out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
