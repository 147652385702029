class Icons {
  static dashboard: string = "/icons/dashboard.png";
  static service_categories: string = "/icons/categories.png";
  static sales_categories: string = "/icons/categories.png";
  static service_models: string = "/icons/service-models.png";
  static service_types: string = "/icons/service-type.png";
  static service_plans: string = "/icons/service-type.png";
  static services: string = "/icons/service-type.png";
  static service_orders: string = "/icons/service-orders.png";
  static sales_orders: string = "/icons/sales-orders.png";
  static sales_items: string = "/icons/sales-items.png";
  static users: string = "/icons/users.png";
  static admins: string = "/icons/admins.png";
  static profile: string = "/icons/profile.png";
  static settings: string = "/icons/setting.png";
  static menu: string = "/icons/menu.png";
  static close: string = "/icons/close.png";
  static noData: string = "/svgs/no_data.svg";
  static logo: string = "/images/tn_logo.png";
}

export default Icons;
