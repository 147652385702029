import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITicket } from "../types/ticket";

interface ITicketState {
  tickets: ITicket[];
  assignedTickets: ITicket[];
  isLoading: boolean;
}

const ticketInitState: ITicketState = {
  tickets: [],
  assignedTickets: [],
  isLoading: false,
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState: ticketInitState,
  reducers: {
    changeTicketLoading: (
      state: ITicketState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setTicketsState: (
      state: ITicketState,
      action: PayloadAction<{ tickets: ITicket[]; refresh?: boolean }>
    ) => {
      if (state.tickets.length <= 0 || action.payload.refresh) {
        state.tickets = action.payload.tickets || state.tickets;
      }
      state.isLoading = false;
    },
    setAssignedTicketsState: (
      state: ITicketState,
      action: PayloadAction<{ tickets: ITicket[]; refresh?: boolean }>
    ) => {
      if (state.assignedTickets.length <= 0 || action.payload.refresh) {
        state.assignedTickets = action.payload.tickets || state.tickets;
      }
      state.isLoading = false;
    },
    addTicketState: (state: ITicketState, action: PayloadAction<ITicket>) => {
      state.tickets.unshift(action.payload);
      state.isLoading = false;
    },
    editTicketState: (state: ITicketState, action: PayloadAction<ITicket>) => {
      state.tickets = state.tickets.map((ticket) => {
        if (action.payload._id === ticket._id) {
          ticket = action.payload;
        }
        return ticket;
      });
      state.isLoading = false;
    },
    assignTicketState: (
      state: ITicketState,
      action: PayloadAction<ITicket>
    ) => {
      state.tickets = state.tickets.map((ticket) => {
        if (action.payload._id === ticket._id) {
          ticket = action.payload;
        }
        return ticket;
      });
      state.isLoading = false;
    },
    changeTicketBranchState: (
      state: ITicketState,
      action: PayloadAction<ITicket>
    ) => {
      state.tickets = state.tickets.map((ticket) => {
        if (action.payload._id === ticket._id) {
          ticket = action.payload;
        }
        return ticket;
      });
      state.isLoading = false;
    },
    changeTicketStatusState: (
      state: ITicketState,
      action: PayloadAction<ITicket>
    ) => {
      state.tickets = state.tickets.map((ticket) => {
        if (action.payload._id === ticket._id) {
          ticket = action.payload;
        }
        return ticket;
      });
      state.isLoading = false;
    },
    deleteTicketState: (
      state: ITicketState,
      action: PayloadAction<ITicket["_id"]>
    ) => {
      state.tickets = state.tickets.filter(
        (ticket) => ticket._id !== action.payload
      );
      state.isLoading = false;
    },
    clearTicketState: (state: ITicketState) => {
      state.tickets = [];
      state.assignedTickets = [];
      state.isLoading = false;
    },
  },
});

export const {
  changeTicketLoading,
  setTicketsState,
  setAssignedTicketsState,
  addTicketState,
  editTicketState,
  assignTicketState,
  changeTicketBranchState,
  changeTicketStatusState,
  deleteTicketState,
  clearTicketState,
} = ticketSlice.actions;

export default ticketSlice.reducer;
