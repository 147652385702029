import { Column } from "react-table";
import {
  ButtonsPill,
  DateFromatPill,
  StatusPill,
} from "../../components/TableCells";
import { KRoutes } from "../../routers/router";
import {
  changeManagerStatus,
  deleteManager,
} from "../../services/managers/manager";
import { IManager } from "../../types/manager";
import { IBranch } from "../../types/branch";

const managerTableHeaders: Column<IManager>[] = [
  {
    id: "code",
    Header: "Code",
    accessor: "code",
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "email",
    Header: "Email",
    accessor: "email",
  },
  {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
  },
  {
    id: "branch",
    Header: "Branch",
    accessor: (b) => {
      return b.branch ? (b.branch as IBranch).name : "No Branch";
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },

  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: false,
        editButton: true,
        deleteButton: true,
        editPath: KRoutes.editManager + "/" + value,
        onClickStatusChange: () => changeManagerStatus(value),
        onClickDelete: () => deleteManager(value),
      });
    },
  },
];

export default managerTableHeaders;
