import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import store, { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeUserLoading,
  setUsersState,
  addUserState,
  changeUserStatusState,
  deleteUserState,
  editUserState,
} from "../../redux/user";

/**
 * To get a  users from server
 */
export const getUsers = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.user);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setUsersState({
        users: resp.data.results,
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To add a new  user
 */
export const addUser = async (userData: any) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.user, userData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addUserState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To add a new  user
 */
export const editUser = async (id: string, userForm: any) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.user + "/" + id,
      userForm
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editUserState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To change the status
 */
export const changeUserStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.changeUserStatus + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeUserStatusState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To delete the user
 */
export const deleteUser = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.user + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteUserState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeUserLoading(false));
  }
};
