import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeManagerLoading,
  setManagersState,
  addManagerState,
  changeManagerStatusState,
  deleteManagerState,
  editManagerState,
} from "../../redux/manager";

/**
 * To get a  managers from server
 */
export const getManagers = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeManagerLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.managerAdmin);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setManagersState({
        managers: resp.data.results,
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeManagerLoading(false));
  }
};

/**
 * To add a new  manager
 */
export const addManager = async (managerData: any) => {
  try {
    // Set Loading
    dispatch(changeManagerLoading(true));

    // API Fetching
    const resp = await httpClient().post(
      ApiEndpoints.managerAdmin,
      managerData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addManagerState(resp.data.results));
    dispatch(setSuccessMessage("Manager added successfully"));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeManagerLoading(false));
  }
};

/**
 * To add a new  manager
 */
export const editManager = async (id: string, managerData: any) => {
  try {
    // Set Loading
    dispatch(changeManagerLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.manager + "/" + id,
      managerData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editManagerState(resp.data.results));
    dispatch(setSuccessMessage("Manager edited successfully"));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeManagerLoading(false));
  }
};

/**
 * To change the status
 */
export const changeManagerStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeManagerLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.changeManagerStatus + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeManagerStatusState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeManagerLoading(false));
  }
};

/**
 * To delete the manager
 */
export const deleteManager = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeManagerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.manager + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteManagerState(id));
    dispatch(setSuccessMessage("Manager deleted successfully"));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeManagerLoading(false));
  }
};
