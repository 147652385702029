import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IManager } from "../types/manager";

interface IManagerState {
  managers: IManager[];
  isLoading: boolean;
}

const managerInitState: IManagerState = {
  managers: [],
  isLoading: false,
};

const managerSlice = createSlice({
  name: "managers",
  initialState: managerInitState,
  reducers: {
    changeManagerLoading: (
      state: IManagerState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setManagersState: (
      state: IManagerState,
      action: PayloadAction<{ managers: IManager[]; refresh?: boolean }>
    ) => {
      if (state.managers.length <= 0 || action.payload.refresh) {
        state.managers = action.payload.managers || state.managers;
      }
      state.isLoading = false;
    },
    addManagerState: (
      state: IManagerState,
      action: PayloadAction<IManager>
    ) => {
      state.managers.unshift(action.payload);
      state.isLoading = false;
    },
    editManagerState: (
      state: IManagerState,
      action: PayloadAction<IManager>
    ) => {
      state.managers = state.managers.map((manager) => {
        if (action.payload._id === manager._id) {
          manager = action.payload;
        }
        return manager;
      });
      state.isLoading = false;
    },
    changeManagerStatusState: (
      state: IManagerState,
      action: PayloadAction<IManager>
    ) => {
      state.managers = state.managers.map((manager) => {
        if (
          action.payload._id === manager._id &&
          action.payload.status !== manager.status
        ) {
          manager.status = action.payload.status;
        }
        return manager;
      });
      state.isLoading = false;
    },
    deleteManagerState: (
      state: IManagerState,
      action: PayloadAction<IManager["_id"]>
    ) => {
      state.managers = state.managers.filter(
        (manager) => manager._id !== action.payload
      );
      state.isLoading = false;
    },
    clearManagerState: (state: IManagerState) => {
      state.managers = [];
      state.isLoading = false;
    },
  },
});

export const {
  changeManagerLoading,
  setManagersState,
  addManagerState,
  editManagerState,
  changeManagerStatusState,
  deleteManagerState,
  clearManagerState,
} = managerSlice.actions;

export default managerSlice.reducer;
