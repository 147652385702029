import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWorker } from "../types/worker";

interface IWorkerState {
  workers: IWorker[];
  isLoading: boolean;
}

const workerInitState: IWorkerState = {
  workers: [],
  isLoading: false,
};

const workerSlice = createSlice({
  name: "workers",
  initialState: workerInitState,
  reducers: {
    changeWorkerLoading: (
      state: IWorkerState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setWorkersState: (
      state: IWorkerState,
      action: PayloadAction<{ workers: IWorker[]; refresh?: boolean }>
    ) => {
      if (state.workers.length <= 0 || action.payload.refresh) {
        state.workers = action.payload.workers || state.workers;
      }
      state.isLoading = false;
    },
    addWorkerState: (state: IWorkerState, action: PayloadAction<IWorker>) => {
      state.workers.unshift(action.payload);
      state.isLoading = false;
    },
    editWorkerState: (state: IWorkerState, action: PayloadAction<IWorker>) => {
      state.workers = state.workers.map((worker) => {
        if (action.payload._id === worker._id) {
          worker = action.payload;
        }
        return worker;
      });
      state.isLoading = false;
    },
    changeWorkerStatusState: (
      state: IWorkerState,
      action: PayloadAction<IWorker>
    ) => {
      state.workers = state.workers.map((worker) => {
        if (
          action.payload._id === worker._id &&
          action.payload.status !== worker.status
        ) {
          worker.status = action.payload.status;
        }
        return worker;
      });
      state.isLoading = false;
    },
    deleteWorkerState: (
      state: IWorkerState,
      action: PayloadAction<IWorker["_id"]>
    ) => {
      state.workers = state.workers.filter(
        (worker) => worker._id !== action.payload
      );
      state.isLoading = false;
    },
    clearWorkerState: (state: IWorkerState) => {
      state.workers = [];
      state.isLoading = false;
    },
  },
});

export const {
  changeWorkerLoading,
  setWorkersState,
  addWorkerState,
  editWorkerState,
  changeWorkerStatusState,
  deleteWorkerState,
  clearWorkerState,
} = workerSlice.actions;

export default workerSlice.reducer;
