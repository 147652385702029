import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeWorkerLoading,
  setWorkersState,
  addWorkerState,
  changeWorkerStatusState,
  deleteWorkerState,
  editWorkerState,
} from "../../redux/worker";

/**
 * To get a  workers from server
 */
export const getWorkers = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeWorkerLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.workerAdmin);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setWorkersState({
        workers: resp.data.results,
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeWorkerLoading(false));
  }
};

/**
 * To add a new  worker
 */
export const addWorker = async (workerData: any) => {
  try {
    // Set Loading
    dispatch(changeWorkerLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.workerAdmin, workerData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addWorkerState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeWorkerLoading(false));
  }
};

/**
 * To add a new  worker
 */
export const editWorker = async (id: string, workerData: any) => {
  try {
    // Set Loading
    dispatch(changeWorkerLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.worker + "/" + id,
      workerData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editWorkerState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeWorkerLoading(false));
  }
};

/**
 * To change the status
 */
export const changeWorkerStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeWorkerLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.changeWorkerStatus + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeWorkerStatusState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeWorkerLoading(false));
  }
};

/**
 * To delete the worker
 */
export const deleteWorker = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeWorkerLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.worker + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteWorkerState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeWorkerLoading(false));
  }
};
