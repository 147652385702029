import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeVipLoading,
  setVipsState,
  addVipState,
  changeVipStatusState,
  deleteVipState,
  editVipState,
} from "../../redux/vip";

/**
 * To get a  vips from server
 */
export const getVips = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeVipLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.vipAdmin);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setVipsState({
        vips: resp.data.results,
        refresh,
      })
    );
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeVipLoading(false));
  }
};

/**
 * To add a new  vip
 */
export const addVip = async (vipData: any) => {
  try {
    // Set Loading
    dispatch(changeVipLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.vipAdmin, vipData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addVipState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeVipLoading(false));
  }
};

/**
 * To add a new  vip
 */
export const editVip = async (id: string, vipData: any) => {
  try {
    // Set Loading
    dispatch(changeVipLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.vip + "/" + id, vipData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editVipState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeVipLoading(false));
  }
};

/**
 * To change the status
 */
export const changeVipStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeVipLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.changeVipStatus + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeVipStatusState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeVipLoading(false));
  }
};

/**
 * To delete the vip
 */
export const deleteVip = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeVipLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.vip + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteVipState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeVipLoading(false));
  }
};
