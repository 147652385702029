import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";
import Table from "../../components/Table";

import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { KRoutes } from "../../routers/router";
import { useNavigate } from "react-router-dom";
import { adminTableHeaders } from "./adminTableHeaders";
import { getAdmins } from "../../services/admins/admin";
import { autoRefreshDelay } from "../../core/constants";

const ScreenAdmins = () => {
  const { admins, profile, isLoading } = useAppSelector(
    (state) => state.admins
  );
  const adminColumns = useMemo(() => adminTableHeaders, [profile]);
  const data = useMemo(() => admins, [admins]);
  const navigate = useNavigate();

  useEffect(() => {
    getAdmins();
    const refreshInterval = setInterval(
      () => getAdmins(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout title="admins">
      {isLoading && admins.length <= 0 ? (
        <Loader />
      ) : (
        <Table
          columns={adminColumns}
          data={data}
          tableTitle={"Admins"}
          buttons={{
            addButton: profile?.role === "SuperAdmin",
            addButtonTitle: "Add Admin",
            onClickAddButton: () => {
              navigate(KRoutes.addAdmin);
            },
            exportButton: false,
          }}
        />
      )}
    </Layout>
  );
};

export default ScreenAdmins;
