import React from "react";
import Layout from "../../components/Layout";
import { GridView, TextField } from "../../components/TextFieldItems";
import { useAppSelector } from "../../redux/hooks";

const ScreenProfile = () => {
  const { profile } = useAppSelector((state) => state.admins);
  return (
    <Layout
      title="profile"
      sidebarHide={profile && profile.role === "BranchWorker"}
    >
      <div className="p-10 overflow-y-scroll overflow-x-hidden scrollbar-hide min-h-full min-w-full">
        <div className="flex justify-between">
          <p className="text-3xl font-black dark:text-white ">Admin Profile</p>
        </div>

        {/* <section className=" py-1 bg-blueGray-50"> */}
        <div className="w-full ">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="flex-auto px-4 lg:px-10 py-10">
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Account Information
              </h6>
              <GridView className="gap-6 mb-6 lg:grid-cols-2">
                <>
                  <TextField
                    label="Name"
                    id="name"
                    labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    type="text"
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="name"
                    value={profile?.name}
                    required={true}
                    placeholder="Name"
                    // onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    label="Email"
                    id="email"
                    labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    type="text"
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="email"
                    value={profile?.email}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    title="Provide valid email id"
                    required={true}
                    placeholder="email"
                    // onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    label="Phone"
                    id="phone"
                    labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    type="text"
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="phone"
                    value={profile?.phone}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    title="Provide valid phone id"
                    required={true}
                    placeholder="Phone"
                  />
                  <TextField
                    label="Role"
                    id="role"
                    labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    type="text"
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="role"
                    value={profile?.role}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    title="Provide valid role id"
                    required={true}
                    placeholder="Role"
                  />
                </>
              </GridView>
              {/* <div className="flex items-center justify-center">
                  <div className="w-full lg:w-1/2 px-3">
                    <button
                      type="submit"
                      className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-2 bg-primary-color text-white  text-lg font-bold rounded-xl"
                    >
                      Save
                    </button>
                  </div>
                </div> */}
              <hr className="mt-6 border-b-1 border-blueGray-300" />
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Change Password
              </h6>
              <GridView className="gap-6 lg:grid-cols-1">
                <>
                  <TextField
                    label="Current Password"
                    id="password"
                    labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    type="text"
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="password"
                    // value={password}
                    required={true}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,24}$"
                    title="
                  At least one lower character, 
                  At least one upper character, 
                  At least one numeric value,
                  At least one special symbol, 
                  Password length should be in between 8 to 24"
                    placeholder="Cureent Password"
                    // onChange={(e) => setPassword(e.target.value)}
                  />
                  <TextField
                    label="New Password"
                    id="password"
                    labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    type="text"
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="password"
                    // value={password}
                    required={true}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,24}$"
                    title="
                  At least one lower character, 
                  At least one upper character, 
                  At least one numeric value,
                  At least one special symbol, 
                  Password length should be in between 8 to 24"
                    placeholder="Password"
                    // onChange={(e) => setPassword(e.target.value)}
                  />
                  <TextField
                    label="Confirm Password"
                    id="password"
                    labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    type="text"
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="password"
                    // value={password}
                    required={true}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,24}$"
                    title="
                  At least one lower character, 
                  At least one upper character, 
                  At least one numeric value,
                  At least one special symbol, 
                  Password length should be in between 8 to 24"
                    placeholder="Confirm Password"
                    // onChange={(e) => setPassword(e.target.value)}
                  />

                  <div className="flex items-center justify-center my-2">
                    <div className="w-full lg:w-1/2 px-3">
                      <button
                        type="submit"
                        className="w-full active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                      >
                        Apply Changes
                      </button>
                    </div>
                  </div>
                </>
              </GridView>
            </div>
          </div>
        </div>
        {/* </section> */}
      </div>
    </Layout>
  );
};

export default ScreenProfile;
