import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ImageViewer from "react-simple-image-viewer";
import { FaLocationDot } from "react-icons/fa6";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
// Import Swiper styles
import "swiper/css";
import { useState } from "react";
import { useAppSelector } from "../redux/hooks";
import { TextField } from "./TextFieldItems";
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";

export interface IPillProps {
  column: any;
  row: any;
  cell: any;
  value: any;
}

interface IButtonPillsProps {
  statusChangeButton?: boolean;
  visibilityChangeButton?: boolean;
  editButton?: boolean;
  deleteButton?: boolean;
  editPath?: string;
  editButtonTitle?: string;
  onClickStatusChange?: React.MouseEventHandler<HTMLButtonElement>;
  onClickVisibilityChange?: React.MouseEventHandler<HTMLButtonElement>;
  onClickDelete?: React.MouseEventHandler<HTMLButtonElement>;
}

interface ITicketButtonPillsProps {
  currentTicket: string;
  currentBranch: string;
  assignChangeButton?: boolean;
  branchChangeButton?: boolean;
  deleteButton?: boolean;
  rejectButton?: boolean;
  onClickReject?: (reason: string) => void;
  onClickBranchChange?: (id: string) => void;
  onClickAssign?: (id: string) => void;
  onClickDelete?: React.MouseEventHandler<HTMLButtonElement>;
}

export const StatusPill = ({ value }: IPillProps) => {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={`
            "px-1 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm"
            ${status === "active" && "bg-green-100 text-green-800"} 
            ${status === "inactive" && "bg-yellow-100 text-yellow-800"}
            ${status === "blocked" && "bg-red-100 text-red-800"}
          )
        `}
    >
      {status}
    </span>
  );
};

export const TicketStatusPill = ({ value }: IPillProps) => {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={`
            "px-1 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm"
            ${status === "pending" && "bg-blue-100 text-blue-800"} 
            ${status === "assigned" && "bg-yellow-100 text-yellow-800"}
            ${status === "completed" && "bg-green-100 text-green-800"}
            ${status === "cancelled" && "bg-red-100 text-red-800"}
            ${status === "rejected" && "bg-red-100 text-red-800"}
          )
        `}
    >
      {status}
    </span>
  );
};

export const VisibilityPill = ({ value }: IPillProps) => {
  const visibility = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={`
            "px-1 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm"
            ${visibility === "show" && "bg-green-100 text-green-800"} 
            ${visibility === "hide" && "bg-red-100 text-red-800"}
          )
        `}
    >
      {visibility}
    </span>
  );
};

export const ResizeTextPill = ({ value }: IPillProps) => {
  return (
    <>
      {value ? (
        value.length < 10 ? (
          value
        ) : (
          <Popup
            trigger={
              <p className="text-sm text-gray-500">
                {value?.substring(0, 10)}...
              </p>
            }
            position="right center"
            contentStyle={{ width: "500px" }}
          >
            <p className="text-sm text-gray-500">{value}</p>
          </Popup>
        )
      ) : (
        "No Note"
      )}
    </>
  );
};

export const ButtonsPill = ({
  editButton = false,
  deleteButton = false,
  visibilityChangeButton = false,
  statusChangeButton = false,
  editPath,
  editButtonTitle,
  onClickDelete,
  onClickStatusChange,
  onClickVisibilityChange,
}: IButtonPillsProps) => {
  const navigate = useNavigate();
  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      <button
        type="button"
        className={`${
          !statusChangeButton && "hidden"
        } py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
        onClick={onClickStatusChange}
      >
        Change Status
      </button>
      <button
        type="button"
        className={`${
          !visibilityChangeButton && "hidden"
        } py-2 px-4 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
        onClick={onClickVisibilityChange}
      >
        Change Visibility
      </button>
      <button
        type="button"
        className={`${
          !editButton && "hidden"
        } py-2 px-4 text-sm font-medium text-gray-900 bg-white ${
          !deleteButton ? "rounded-r-md border" : "border-t border-b"
        } border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
        onClick={() => {
          typeof editPath === "string" && navigate(editPath);
        }}
      >
        {editButtonTitle ?? "Edit"}
      </button>
      <button
        type="button"
        className={`${
          !deleteButton && "hidden"
        } py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
        onClick={onClickDelete}
      >
        Delete
      </button>
    </div>
  );
};

export const TicketButtonsPill = ({
  currentTicket,
  currentBranch,
  deleteButton = false,
  rejectButton = false,
  assignChangeButton = false,
  branchChangeButton = false,
  onClickAssign,
  onClickDelete,
  onClickReject,
  onClickBranchChange,
}: ITicketButtonPillsProps) => {
  const { workers } = useAppSelector((state) => state.workers);
  const { branches } = useAppSelector((state) => state.branches);
  const [reason, setReason] = useState("");
  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      <Popup
        trigger={
          <button
            type="button"
            className={`${
              !assignChangeButton && "hidden"
            } py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
          >
            Assign Member
          </button>
        }
        position="left center"
        contentStyle={{ width: "500px" }}
      >
        <div className="px-6 my-2 z-50 max-h-60 overflow-y-auto scrollbar-thin scrollbar-thumb-indigo-500 scrollbar-track-gray-200">
          <h2 className="text-xl font-semibold mt-6 mb-2">Workers List</h2>
          <ul className="list-disc pl-6">
            {workers
              .filter(
                ({ status, branch }) =>
                  status === "Active" &&
                  branch &&
                  ((typeof branch === "string" &&
                    branch.toString() === currentBranch) ||
                    (typeof branch !== "string" &&
                      branch._id.toString() === currentBranch))
              )
              .map((worker) => (
                <li
                  key={worker._id}
                  className="flex items-center justify-between mb-2"
                >
                  <span>{worker.name} - </span>
                  <button
                    type="button"
                    className={`bg-indigo-500 text-white px-2 py-2 rounded-full hover:bg-indigo-600 focus:outline-none focus:shadow-outline-blue `}
                    onClick={() => onClickAssign && onClickAssign(worker._id)}
                  >
                    Select
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </Popup>

      <Popup
        trigger={
          <button
            type="button"
            className={`${
              !branchChangeButton && "hidden"
            } py-2 px-4 text-sm font-medium text-gray-900 bg-white ${
              !assignChangeButton ? "rounded-l-md border" : "border-t border-b"
            } ${
              !rejectButton ? "rounded-r-md border" : "border-t border-b"
            } border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
          >
            Change Branch
          </button>
        }
        position="left center"
        contentStyle={{ width: "500px" }}
      >
        <div className="px-8 my-2 z-50">
          <h2 className="text-xl font-semibold mt-6 mb-2">Branch List</h2>
          <ul className="list-disc pl-6">
            {branches
              .filter(
                ({ status, visibility, _id, manager }) =>
                  status === "Active" &&
                  // visibility === "Show"
                  //  && manager &&
                  _id.toString() !== currentBranch
              )
              .map((branch) => (
                <li
                  key={branch._id}
                  className="flex items-center justify-between mb-2"
                >
                  <span>{branch.name} - </span>
                  <button
                    type="button"
                    className={`bg-indigo-500 text-white px-2 py-2 rounded-full hover:bg-indigo-600 focus:outline-none focus:shadow-outline-blue `}
                    disabled={branch.status === "Inactive"}
                    onClick={() =>
                      onClickBranchChange && onClickBranchChange(branch._id)
                    }
                  >
                    Select
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </Popup>

      <Popup
        trigger={
          <button
            type="button"
            className={`${
              !rejectButton && "hidden"
            } py-2 px-4 text-sm font-medium text-gray-900 bg-white  ${
              !deleteButton ? "rounded-r-md border" : "border-t border-b"
            }  border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
          >
            Reject
          </button>
        }
        position="left center"
        contentStyle={{ width: "500px" }}
      >
        <div className="px-8 my-2 z-50">
          <h2 className="text-xl font-semibold mt-6 mb-2">Reason</h2>
          <TextField
            label="Reason"
            id="reason"
            labelClassName="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            type="text"
            inputClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            name="reason"
            value={reason}
            required={true}
            placeholder="Reason"
            onChange={(e) => setReason(e.target.value)}
          />
          <button
            type="button"
            className={`bg-indigo-500 text-white px-2 py-2 rounded-full hover:bg-indigo-600 focus:outline-none focus:shadow-outline-blue `}
            onClick={() => onClickReject && onClickReject(reason)}
          >
            Submit
          </button>
        </div>
      </Popup>

      <button
        type="button"
        className={`${!deleteButton && "hidden"} ${
          rejectButton ? "rounded-r-md border" : "border-t border-b"
        } py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white`}
        onClick={onClickDelete}
      >
        Delete
      </button>
    </div>
  );
};

export const DateFromatPill = ({ value }: IPillProps) => {
  const date = new Date(value);
  return (
    <span className="text-sm text-gray-500">
      {" "}
      {date.toDateString() ?? "No Date"}
    </span>
  );
};

export const LocationPill = ({ value }: IPillProps) => {
  return (
    <a
      href={value}
      target="__blank"
      className="flex items-center gap-1 cursor-pointer text-sm text-gray-500"
    >
      <FaLocationDot color="green" /> Direction
    </a>
  );
};

export const TextPill = ({
  value,
  className,
}: {
  value: string;
  className?: string;
}) => {
  return <span className={className ?? ""}> {value}</span>;
};

export function AvatarCell({ value, column, row }: IPillProps) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img
          className="h-10 w-10 rounded-full"
          src={row.original[column.imgAccessor]}
          alt=""
        />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900">{value}</div>
        <div className="text-sm text-gray-500">
          {row.original[column.emailAccessor]}
        </div>
      </div>
    </div>
  );
}

export function ImageCell(imageURL: string) {
  return (
    <div className="flex items-center">
      {/* <div className="flex-shrink-0 h-10 w-10"> */}
      <img className="rounded w-26 h-26" src={imageURL} alt="cell" />
      {/* </div> */}
    </div>
  );
}

export function SwipperImageCell(imageURLs: string[]) {
  const [isOpen, setIsOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  return (
    <div className="w-26 h-26">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        speed={1000}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        style={{ width: "32px" }}
      >
        {imageURLs.map((imageURL, index) => (
          <SwiperSlide style={{ width: "32px" }}>
            <img
              className="rounded"
              width={32}
              height={32}
              src={imageURL}
              alt="cell"
              onClick={() => {
                setOpenIndex(index);
                setIsOpen(true);
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            height: "80%",
            zIndex: 999,
          }}
        >
          <ImageViewer
            backgroundStyle={{
              width: "100%",
              height: "100%",
              background: "white",
              border: "2px solid",
              borderRadius: "10px",
              padding: "10px",
            }}
            src={imageURLs}
            currentIndex={openIndex}
            disableScroll={true}
            closeOnClickOutside={true}
            closeComponent={
              <span
                className="styles-module_close__2I1sI react-simple-image-viewer__close"
                style={{ color: "black", opacity: 1 }}
              >
                ×
              </span>
            }
            rightArrowComponent={
              <span
                className="styles-module_navigation__1pqAE styles-module_next__1uQwZ react-simple-image-viewer__next"
                style={{ color: "black", opacity: 1 }}
              >
                ❯
              </span>
            }
            leftArrowComponent={
              <span
                className="styles-module_navigation__1pqAE styles-module_prev__KqFRp react-simple-image-viewer__previous"
                style={{ color: "black", opacity: 1 }}
              >
                ❮
              </span>
            }
            onClose={() => {
              setOpenIndex(-1);
              setIsOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
}
