import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeBranchLoading,
  setBranchesState,
  addBranchState,
  editBranchState,
  changeBranchStatusState,
  deleteBranchState,
  changeBranchVisibilityState,
} from "../../redux/branch";

/**
 * To get a  branches from server
 */
export const getBranches = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeBranchLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.branch);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setBranchesState({ branches: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeBranchLoading(false));
  }
};

/**
 * To get a  branches from server
 */
export const getBranchList = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeBranchLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.branchList);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setBranchesState({ branches: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeBranchLoading(false));
  }
};

/**
 * To add a new  branch
 */
export const addBranch = async (branchData: any) => {
  try {
    // Set Loading
    dispatch(changeBranchLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.branch, branchData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addBranchState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeBranchLoading(false));
  }
};

/**
 * To add a new  branch
 */
export const editBranch = async (id: string, branchData: any) => {
  try {
    // Set Loading
    dispatch(changeBranchLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.branch + "/" + id,
      branchData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editBranchState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeBranchLoading(false));
  }
};

/**
 * To change the status
 */
export const changeBranchStatus = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeBranchLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.changeBranchStatus + id);
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeBranchStatusState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeBranchLoading(false));
  }
};

/**
 * To change the visibility
 */
export const changeBranchVisibility = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeBranchLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.changeBranchVisibility + id
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeBranchVisibilityState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeBranchLoading(false));
  }
};

/**
 * To delete the branch
 */
export const deleteBranch = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeBranchLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.branch + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteBranchState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeBranchLoading(false));
  }
};
