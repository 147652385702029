import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";
import Table from "../../components/Table";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import branchesTableHeaders from "./branchesTableHeaders";
import { useNavigate } from "react-router-dom";
import { KRoutes } from "../../routers/router";
import { getBranches } from "../../services/branches/branch";
import { autoRefreshDelay } from "../../core/constants";

const ScreenBranches = () => {
  const { branches, isLoading } = useAppSelector((state) => state.branches);
  const columns = useMemo(() => branchesTableHeaders, []);
  const data = useMemo(() => branches, [branches]);
  const navigate = useNavigate();

  useEffect(() => {
    getBranches();
    const refreshInterval = setInterval(
      () => getBranches(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout title="branches">
      {isLoading && branches.length <= 0 ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={data}
          tableTitle={"Branches"}
          buttons={{
            addButton: true,
            addButtonTitle: "Add Branch",
            exportButton: false,
            onClickAddButton: () => navigate(KRoutes.addBranch),
          }}
        />
      )}
    </Layout>
  );
};

export default ScreenBranches;
