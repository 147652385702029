import { Column } from "react-table";
import { SelectColumnFilter } from "../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  // ImageCell,
  StatusPill,
} from "../../components/TableCells";
import { IUser } from "../../types/user";
import { KRoutes } from "../../routers/router";
import { changeUserStatus, deleteUser } from "../../services/users/user";

const usersTableHeaders: Column<IUser>[] = [
  {
    id: "code",
    Header: "Code",
    accessor: "code",
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "email",
    Header: "Email",
    accessor: "email",
  },
  {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },

  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: false,
        editButton: true,
        deleteButton: true,
        editPath: KRoutes.editUser + "/" + value,
        onClickStatusChange: () => changeUserStatus(value),
        onClickDelete: () => deleteUser(value),
      });
    },
  },
];

export default usersTableHeaders;
