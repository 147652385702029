class ApiEndpoints {
  static serverURL: string =
    process.env.REACT_APP_SERVER_URL ||
    "https://ecoinnovators-server.onrender.com";

  static baseApiUrl: string = `${this.serverURL}/api/v1`;
  static baseImageUrl: string = `${this.serverURL}/image`;

  // Admin
  static admin: string = "/admin";
  static changeAdminStatus: string = `${this.admin}/change-status/`;
  static deleteAdmin: string = `${this.admin}/delete/`;
  static login: string = `${this.admin}/login`;
  static logout: string = `${this.admin}/logout`;
  static adminProfile: string = `${this.admin}/profile`;
  static adminUpdateFToken: string = `${this.admin}/update-ftoken`;

  // Vip
  static vip: string = "/admin";
  static vipAdmin: string = "/admin/vip";
  static changeVipStatus: string = `${this.vip}/change-status/`;
  static deleteVip: string = `${this.vip}/delete/`;

  // Manager
  static manager: string = "/admin";
  static managerAdmin: string = "/admin/manager";
  static changeManagerStatus: string = `${this.manager}/change-status/`;
  static deleteManager: string = `${this.manager}/delete/`;

  // Worker
  static worker: string = "/admin";
  static workerAdmin: string = "/admin/worker";
  static changeWorkerStatus: string = `${this.worker}/change-status/`;
  static deleteWorker: string = `${this.worker}/delete/`;

  // User
  static user: string = "/user";
  static changeUserStatus: string = `${this.user}/change-status/`;
  static deleteUser: string = `${this.user}/delete/`;

  // Branch
  static branch: string = "/branch";
  static branchList: string = "/branch/list";
  static changeBranchStatus: string = `${this.branch}/change-status/`;
  static changeBranchVisibility: string = `${this.branch}/change-visibility/`;

  // Ticket
  static ticket: string = "/ticket";
  static managerTickets: string = "/ticket/manager";
  static workerTickets: string = "/ticket/worker";
  static assignTicket: string = `${this.ticket}/assign/`;
  static changeTicketBranch: string = `${this.ticket}/change-branch/`;
  static changeTicketStatus: string = `${this.ticket}/change-status/`;
}

export default ApiEndpoints;
