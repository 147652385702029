import { configureStore } from "@reduxjs/toolkit";
import notificationReducer from "./notification";
import settingsReducer from "./settings";
import adminReducer from "./admin";
import ticketReducer from "./ticket";
import branchReducer from "./branch";
import userReducer from "./user";
import managerReducer from "./manager";
import vipReducer from "./vip";
import workerReducer from "./worker";

const store = configureStore({
  reducer: {
    admins: adminReducer,
    vips: vipReducer,
    managers: managerReducer,
    workers: workerReducer,
    users: userReducer,
    branches: branchReducer,
    tickets: ticketReducer,
    settings: settingsReducer,
    notification: notificationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const dispatch = store.dispatch;
export default store;
