import React, { useEffect, useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";
import Table from "../../components/Table";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { KRoutes } from "../../routers/router";
import managerTableHeaders from "./managerTableHeaders";
import { getManagers } from "../../services/managers/manager";
import { autoRefreshDelay } from "../../core/constants";

const ScreenManagers = () => {
  const { managers, isLoading } = useAppSelector((state) => state.managers);
  const columns = useMemo(() => managerTableHeaders, []);
  const data = useMemo(() => managers, [managers]);
  const navigate = useNavigate();
  useEffect(() => {
    getManagers();
    const refreshInterval = setInterval(
      () => getManagers(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout title="managers">
      {isLoading && managers.length <= 0 ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={data}
          tableTitle={"Managers"}
          buttons={{
            addButton: true,
            addButtonTitle: "Add Manager",
            onClickAddButton: () => {
              navigate(KRoutes.addManager);
            },
            exportButton: false,
            exportButtonTitle: "Export",
            onClickExportButton() {
              toast("Feature not available on this version!");
            },
          }}
        />
      )}
    </Layout>
  );
};

export default ScreenManagers;
