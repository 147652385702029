import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { APP_NAME } from "../core/constants";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setActiveMenuId, setOpenSidebar } from "../redux/settings";
import { KRoutes } from "../routers/router";
import { RiAdminFill } from "react-icons/ri";
import { MdAssignment, MdCorporateFare } from "react-icons/md";
import { GrUserManager } from "react-icons/gr";
import { GrUserWorker } from "react-icons/gr";
import { FaUsers } from "react-icons/fa";
import { IoTicket } from "react-icons/io5";
import { IconType } from "react-icons";
import { RiVipLine } from "react-icons/ri";
import Loader from "./Loader";
import { getAdminProfile } from "../services/admins/admin";
import Icons from "../utils/icons";

interface ISidebarTiles {
  id: string;
  title: string;
  icon: IconType;
  path: string;
}

interface ISidebarProps {
  title: string;
}

const Sidebar = ({ title }: ISidebarProps) => {
  const { openSidebar, activeMenuId } = useAppSelector(
    (state) => state.settings
  );
  const { profile, isLoading } = useAppSelector((state) => state.admins);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const superAdminSidebarTileData = useMemo<ISidebarTiles[]>(
    () => [
      {
        id: "admins",
        title: "Admins",
        icon: RiAdminFill,
        path: KRoutes.admins,
      },
      {
        id: "vips",
        title: "Vips",
        icon: RiVipLine,
        path: KRoutes.vips,
      },
      {
        id: "managers",
        title: "Managers",
        icon: GrUserManager,
        path: KRoutes.managers,
      },
      {
        id: "workers",
        title: "Workers",
        icon: GrUserWorker,
        path: KRoutes.workers,
      },
      {
        id: "users",
        title: "User",
        icon: FaUsers,
        path: KRoutes.users,
      },
      {
        id: "branches",
        title: "Branches",
        icon: MdCorporateFare,
        path: KRoutes.branches,
      },
      {
        id: "tickets",
        title: "Tickets",
        icon: IoTicket,
        path: KRoutes.tickets,
      },
    ],
    []
  );
  const adminSidebarTileData = useMemo<ISidebarTiles[]>(
    () => [
      {
        id: "vips",
        title: "Vips",
        icon: RiVipLine,
        path: KRoutes.vips,
      },
      {
        id: "managers",
        title: "Managers",
        icon: GrUserManager,
        path: KRoutes.managers,
      },
      {
        id: "workers",
        title: "Workers",
        icon: GrUserWorker,
        path: KRoutes.workers,
      },
      {
        id: "users",
        title: "User",
        icon: FaUsers,
        path: KRoutes.users,
      },
      {
        id: "branches",
        title: "Branches",
        icon: MdCorporateFare,
        path: KRoutes.branches,
      },
      {
        id: "tickets",
        title: "Tickets",
        icon: IoTicket,
        path: KRoutes.tickets,
      },
      {
        id: "assigned-tickets",
        title: "Assigned Tickets",
        icon: MdAssignment,
        path: KRoutes.assignedTickets,
      },
    ],
    []
  );
  const vipSidebarTileData = useMemo<ISidebarTiles[]>(
    () => [
      {
        id: "managers",
        title: "Managers",
        icon: GrUserManager,
        path: KRoutes.managers,
      },
      {
        id: "workers",
        title: "Workers",
        icon: GrUserWorker,
        path: KRoutes.workers,
      },
      {
        id: "users",
        title: "User",
        icon: FaUsers,
        path: KRoutes.users,
      },
      {
        id: "branches",
        title: "Branches",
        icon: MdCorporateFare,
        path: KRoutes.branches,
      },
      {
        id: "tickets",
        title: "Tickets",
        icon: IoTicket,
        path: KRoutes.tickets,
      },
    ],
    []
  );
  const managerSidebarTileData = useMemo<ISidebarTiles[]>(
    () => [
      {
        id: "tickets",
        title: "Tickets",
        icon: IoTicket,
        path: KRoutes.tickets,
      },
      {
        id: "workers",
        title: "Workers",
        icon: GrUserWorker,
        path: KRoutes.workers,
      },
    ],
    []
  );
  const workerSidebarTileData = useMemo<ISidebarTiles[]>(
    () => [
      {
        id: "assigned-tickets",
        title: "Assigned Tickets",
        icon: MdAssignment,
        path: KRoutes.assignedTickets,
      },
    ],
    []
  );

  const sidebarTileData = !profile
    ? []
    : profile.role === "SuperAdmin"
    ? superAdminSidebarTileData
    : profile.role === "Admin"
    ? adminSidebarTileData
    : profile.role === "VIP"
    ? vipSidebarTileData
    : profile.role === "BranchManager"
    ? managerSidebarTileData
    : profile.role === "BranchWorker"
    ? workerSidebarTileData
    : [];

  useEffect(() => {
    if (!profile) {
      getAdminProfile();
    }
    dispatch(setActiveMenuId(title));
  }, [dispatch, title, profile]);

  return (
    <div
      className={`h-screen bg-primary-color overflow-y-scroll overflow-x-hidden scrollbar-hide fixed z-10 top-0 left-0 ${
        openSidebar ? "w-72" : "w-20  hidden lg:block"
      }`}
    >
      <img
        className={`hidden cursor-pointer fixed  top-12 w-7 border-2 border-primary-color rounded-full z-50 ${
          openSidebar ? "left-64" : "rotate-180 left-16"
        }`}
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQfMRNlXpLne8jaIc3q3ZOkpNz7TrBfK6zvw&usqp=CAU"
        alt={`${openSidebar ? "open" : "close"}`}
        onClick={() => dispatch(setOpenSidebar(!openSidebar))}
      />
      <div
        className={`flex ${
          openSidebar ? "gap-x-4 w-72" : "w-20"
        } items-center justify-start h-20 fixed top-0 left-0 bg-primary-color`}
      >
        <img
          className={`cursor-pointer duration-500  ${
            openSidebar ? "rotate-[360deg] ml-6" : "ml-5"
          }`}
          src={Icons.logo}
          alt="Logo"
          width={openSidebar ? 50 : 40}
        />
        <h1
          className={`text-white origin-left font-medium text-xl duration-300 ml-2  ${
            !openSidebar && "scale-0"
          }`}
        >
          {APP_NAME.toUpperCase()}
        </h1>
      </div>
      <div className="p-5 pt-2 mt-20">
        {isLoading ? (
          <Loader />
        ) : (
          <ul>
            {sidebarTileData.map(({ id, title, icon, path }: ISidebarTiles) => (
              <li
                key={id}
                className={`${openSidebar ? "my-1.5" : "my-4"} ${
                  id === activeMenuId && "bg-light-white"
                } first:mt-0 text-white text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md  `}
                onClick={() => {
                  dispatch(setActiveMenuId(id));
                  navigate(path);
                }}
              >
                {" "}
                {React.createElement(icon, {})}
                <span
                  className={`${
                    !openSidebar && "hidden"
                  } origin-left duration-200`}
                >
                  {title}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
