import { Column } from "react-table";
import { SelectColumnFilter } from "../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  StatusPill,
  TextPill,
  VisibilityPill,
} from "../../components/TableCells";
import store from "../../redux/store";
import { KRoutes } from "../../routers/router";
import {
  changeBranchStatus,
  changeBranchVisibility,
  deleteBranch,
} from "../../services/branches/branch";
import { IBranch } from "../../types/branch";

const branchesTableHeaders: Column<IBranch>[] = [
  {
    id: "code",
    Header: "Code",
    accessor: "code",
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "manager",
    Header: "Manager",
    accessor: (branch) => {
      return branch.manager ? branch.manager.name : "No Manager";
    },
  },
  {
    id: "workers",
    Header: "Workers",
    accessor: (branch) => {
      return branch.workers.length;
    },
  },
  {
    id: "location",
    Header: "Location",
    accessor: "location",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
  },
  {
    Header: "Visibility",
    accessor: "visibility",
    Cell: VisibilityPill,
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },

  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: true,
        editButton: true,
        deleteButton: ["SuperAdmin", "Developer"].includes(
          store.getState().admins.profile?.role || ""
        )
          ? true
          : false,
        editPath: KRoutes.editBranch + "/" + value,
        onClickVisibilityChange: () => changeBranchVisibility(value),
        onClickStatusChange: () => changeBranchStatus(value),
        onClickDelete: () => deleteBranch(value),
      });
    },
  },
];

export default branchesTableHeaders;
