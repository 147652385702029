import httpClient from "../../core/axios";
import ApiEndpoints from "../../core/apiEndpoints";
import { dispatch } from "../../redux/store";
import { setErrorMessage, setSuccessMessage } from "../../redux/notification";
import {
  changeTicketLoading,
  setTicketsState,
  setAssignedTicketsState,
  addTicketState,
  changeTicketStatusState,
  editTicketState,
  deleteTicketState,
  assignTicketState,
  changeTicketBranchState,
} from "../../redux/ticket";

/**
 * To get a  tickets from server
 */
export const getTickets = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeTicketLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.ticket);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setTicketsState({ tickets: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTicketLoading(false));
  }
};

/**
 * To get a manager tickets from server
 */
export const getManagerTickets = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeTicketLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.managerTickets);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setTicketsState({ tickets: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTicketLoading(false));
  }
};

/**
 * To get assigned tickets from server
 */
export const getAssignedTickets = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeTicketLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.workerTickets);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setAssignedTicketsState({ tickets: resp.data.results, refresh }));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTicketLoading(false));
  }
};

/**
 * To add a new  ticket
 */
export const addTicket = async (ticketData: any) => {
  try {
    // Set Loading
    dispatch(changeTicketLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.ticket, ticketData);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(addTicketState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTicketLoading(false));
  }
};

/**
 * To add a new  ticket
 */
export const editTicket = async (id: string, ticketData: any) => {
  try {
    // Set Loading
    dispatch(changeTicketLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.ticket + "/" + id,
      ticketData
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(editTicketState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTicketLoading(false));
  }
};

/**
 * To assign the status
 */
export const assignTicket = async (id: string, memberId: string) => {
  try {
    // Set Loading
    dispatch(changeTicketLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.assignTicket + id + "/" + memberId
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(assignTicketState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTicketLoading(false));
  }
};

/**
 * To change the branch
 */
export const changeTicketBranch = async (id: string, branchId: string) => {
  try {
    // Set Loading
    dispatch(changeTicketLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.changeTicketBranch + id + "/" + branchId
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeTicketBranchState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTicketLoading(false));
  }
};

/**
 * To reject the status
 */
export const rejectTicketStatus = async (id: string, reason: string) => {
  try {
    // Set Loading
    dispatch(changeTicketLoading(true));

    // API Fetching
    const resp = await httpClient().patch(
      ApiEndpoints.changeTicketStatus + id,
      { status: "REJECTED", rejectedReason: reason }
    );
    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeTicketStatusState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTicketLoading(false));
  }
};

/**
 * To resolve the status
 */
export const resolveTicketStatus = async (id: string, data: FormData) => {
  try {
    // Set Loading
    dispatch(changeTicketLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").patch(
      ApiEndpoints.changeTicketStatus + id,
      data
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeTicketStatusState(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTicketLoading(false));
  }
};

/**
 * To delete the ticket
 */
export const deleteTicket = async (id: string) => {
  try {
    // Set Loading
    dispatch(changeTicketLoading(true));

    // API Fetching
    const resp = await httpClient().delete(ApiEndpoints.ticket + "/" + id);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(deleteTicketState(id));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeTicketLoading(false));
  }
};
