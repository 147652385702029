import { Column } from "react-table";
import { SelectColumnFilter } from "../../components/Table";
import {
  ButtonsPill,
  DateFromatPill,
  StatusPill,
} from "../../components/TableCells";
import { KRoutes } from "../../routers/router";
import { changeVipStatus, deleteVip } from "../../services/vips/vip";
import { IVip } from "../../types/vip";

const vipTableHeaders: Column<IVip>[] = [
  {
    id: "code",
    Header: "Code",
    accessor: "code",
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "email",
    Header: "Email",
    accessor: "email",
  },
  {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusPill,
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
    Cell: DateFromatPill,
  },

  {
    Header: "Action",
    accessor: "_id",
    Cell: ({ value }) => {
      return ButtonsPill({
        statusChangeButton: true,
        visibilityChangeButton: false,
        editButton: true,
        deleteButton: true,
        editPath: KRoutes.editVip + "/" + value,
        onClickStatusChange: () => changeVipStatus(value),
        onClickDelete: () => deleteVip(value),
      });
    },
  },
];

export default vipTableHeaders;
