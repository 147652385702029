import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalStorageKeys } from "../core/localStorageKeys";
import { IAdmin } from "../types/admin";

interface IAdminState {
  token: string | null;
  profile?: IAdmin;
  admins: IAdmin[];
  isLoading: boolean;
  isLoggedIn: boolean;
}

const adminInitState: IAdminState = {
  token: localStorage.getItem(LocalStorageKeys.adminToken),
  isLoading: false,
  isLoggedIn: localStorage.getItem(LocalStorageKeys.adminToken) ? true : false,
  admins: [],
};

const adminSlice = createSlice({
  name: "admin",
  initialState: adminInitState,
  reducers: {
    changeAdminLoading: (
      state: IAdminState,
      action: PayloadAction<boolean>
    ) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setAdminLoggedIn: (
      state: IAdminState,
      action: PayloadAction<{ token: string; role: string }>
    ) => {
      if (!state.isLoggedIn) {
        localStorage.setItem(LocalStorageKeys.adminToken, action.payload.token);
        localStorage.setItem(LocalStorageKeys.adminRole, action.payload.role);
        // localStorage.setItem(LocalStorageKeys.adminRole, action.payload);
        state.token = action.payload.token;
        state.isLoggedIn = true;
        state.isLoading = false;
      }
    },
    setAdminDetails: (state: IAdminState, action: PayloadAction<IAdmin>) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    setAdminsState: (
      state: IAdminState,
      action: PayloadAction<{ admins: IAdmin[]; refresh?: boolean }>
    ) => {
      if (state.admins.length <= 0 || action.payload.refresh) {
        state.admins = action.payload.admins || state.admins;
      }
      state.isLoading = false;
    },
    addAdminState: (state: IAdminState, action: PayloadAction<IAdmin>) => {
      state.admins.unshift(action.payload);
      state.isLoading = false;
    },
    editAdminState: (state: IAdminState, action: PayloadAction<IAdmin>) => {
      state.admins = state.admins.map((admin) => {
        if (action.payload._id === admin._id) {
          admin = action.payload;
        }
        return admin;
      });
      state.isLoading = false;
    },
    changeAdminStatusState: (
      state: IAdminState,
      action: PayloadAction<IAdmin>
    ) => {
      state.admins = state.admins.map((admin) => {
        if (
          action.payload._id === admin._id &&
          action.payload.status !== admin.status
        ) {
          admin.status = action.payload.status;
        }
        return admin;
      });
      state.isLoading = false;
    },
    deleteAdminState: (
      state: IAdminState,
      action: PayloadAction<IAdmin["_id"]>
    ) => {
      state.admins = state.admins.filter(
        (admin) => admin._id !== action.payload
      );
      state.isLoading = false;
    },
    clearAdminState: (state: IAdminState) => {
      state = adminInitState;
    },
    adminLogoutState: (state: IAdminState) => {
      if (state.isLoggedIn) {
        localStorage.removeItem(LocalStorageKeys.adminToken);
        localStorage.removeItem(LocalStorageKeys.adminRole);
        state.isLoggedIn = false;
        state.isLoading = false;
        state.token = null;
        state.profile = undefined;
        state.admins = [];
      }
    },
  },
});

export const {
  setAdminLoggedIn,
  adminLogoutState,
  setAdminDetails,
  changeAdminLoading,
  setAdminsState,
  addAdminState,
  editAdminState,
  changeAdminStatusState,
  deleteAdminState,
  clearAdminState,
} = adminSlice.actions;

export default adminSlice.reducer;
